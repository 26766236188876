import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { ProgressSpinner } from "primereact/progressspinner";
import { ProgressBar } from "primereact/progressbar";
import { useAuth } from "context/AuthContext";
import { useNotification } from "context/NotificationContext";
import Api from "utils/Api";
import * as XLSX from "xlsx";

const ImportarProduto = () => {
  const [matrizes, setMatrizes] = useState([]);
  const [selectedMatriz, setSelectedMatriz] = useState(null);
  const [isProcessing, setIsProcessing] = useState(false);
  const [file, setFile] = useState(null);
  const [validationError, setValidationError] = useState(""); // Estado para armazenar o erro de validação
  const [showConfirmDialog, setShowConfirmDialog] = useState(false); // Estado para o modal de confirmação
  const [progress, setProgress] = useState(0); // Estado para controlar o progresso
  const [totalRecords, setTotalRecords] = useState(0); // Estado para controlar o total de registros
  const [validationErrorsList, setValidationErrorsList] = useState([]); // Estado para armazenar os erros de validação
  const navigate = useNavigate();
  const auth = useAuth();
  const notify = useNotification();
  const Requicicao = new Api();

  useEffect(() => {
    const fetchMatrizes = async () => {
      try {
        const resposta = await Requicicao.Get({
          endpoint: "/Empresa/ObterMatrizesUsuarioAutenticado",
          config: auth.GetHeaders(),
        });

        if (resposta.codigoEstadoHttp === 200) {
          const matrizesMapeadas = resposta.objetoResposta.map((matriz) => ({
            label: `${matriz.nomeCompletoOuRazaoSocial} (${matriz.apelidoOuNomeFantasia})`,
            value: matriz.id,
          }));
          setMatrizes(matrizesMapeadas);
        } else {
          notify({
            type: "error",
            message: "Erro ao carregar matrizes.",
          });
        }
      } catch (error) {
        console.error("Erro ao carregar matrizes:", error);
        notify({
          type: "error",
          message: "Erro ao carregar matrizes.",
        });
      }
    };

    fetchMatrizes();
  }, []);

  const validateExcel = (worksheet) => {
    const requiredColumns = ["Sku","Codigo", "Descricao", "Grupo", "CodigoGtinEan", "ValorCusto", "ValorVenda"];
    const firstRow = XLSX.utils.sheet_to_json(worksheet, { header: 1 })[0];
    const errors = [];

    // Verificar a ordem das colunas
    let isOrderCorrect = true;
    requiredColumns.forEach((column, index) => {
      if (firstRow[index] !== column) {
        errors.push(`A coluna ${column} está fora de ordem ou ausente.`);
        isOrderCorrect = false;
      }
    });

    // Se a ordem das colunas estiver errada, retornar os erros sem realizar outras validações
    if (!isOrderCorrect) {
      return errors;
    }

    // Verificar se há campos vazios e se os valores estão no formato correto
    const rows = XLSX.utils.sheet_to_json(worksheet);
    rows.forEach((row, rowIndex) => {
      requiredColumns.forEach((column) => {
        if (!row[column]) {
          errors.push(`O campo ${column} está vazio na linha ${rowIndex + 2}.`);
        } else if (["ValorCusto", "ValorVenda"].includes(column)) {
          if (!/^(\d+([.,]\d{1,2})?|R\$\s?\d+([.,]\d{1,2})?)$/.test(row[column])) {
            errors.push(`O campo ${column} está no formato incorreto na linha ${rowIndex + 2}. Exemplo de formato correto: 70,99`);
          }
        }
      });
    });

    return errors;
  };

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleUpload = async () => {
    setValidationError(""); // Resetando o erro de validação antes do upload

    if (!file || !selectedMatriz) {
      notify({
        type: "error",
        message: "Arquivo e Matriz são obrigatórios.",
      });
      return;
    }

    try {
      // Ler o arquivo Excel e validar as colunas
      const workbook = XLSX.read(await file.arrayBuffer(), { type: "array" });
      const worksheet = workbook.Sheets[workbook.SheetNames[0]];

      const validationErrors = validateExcel(worksheet);
      if (validationErrors.length > 0) {
        setValidationErrorsList(validationErrors);
        setValidationError(validationErrors.join("\n"));
        setFile(null); // Resetar o arquivo em caso de erro
        return;
      }

      // Contar a quantidade de registros no Excel
      const rowCount = XLSX.utils.sheet_to_json(worksheet).length;

      setTotalRecords(rowCount); // Definir o total de registros
      setProgress(0); // Resetar o progresso
      setShowConfirmDialog(true); // Exibir o modal de confirmação
    } catch (error) {
      setValidationError("Erro ao processar o arquivo. Tente novamente.");
      setFile(null); // Resetar o arquivo em caso de erro
    }
  };

  const downloadErrors = () => {
    const worksheet = XLSX.utils.json_to_sheet(validationErrorsList.map((error) => ({ Error: error })));
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Erros");
    XLSX.writeFile(workbook, "Erros_Importacao_Produto.xlsx");
  };

  const confirmUpload = async () => {
    setShowConfirmDialog(false);
    setIsProcessing(true); // Mostrar o loading somente após iniciar o upload

    const formData = new FormData();
    formData.append("arquivo", file);
    formData.append("empresaMatrizId", selectedMatriz);

    const intervalTime = 90000 / totalRecords; // Tempo de intervalo calculado para distribuir o progresso em 3 minutos
    let simulatedProgress = 0;

    const interval = setInterval(() => {
      simulatedProgress += 1;
      setProgress(Math.min(simulatedProgress, totalRecords));

      if (simulatedProgress >= totalRecords) {
        clearInterval(interval);
      }
    }, intervalTime); // Intervalo calculado com base no tempo total e número de registros

    try {
      const response = await fetch(
        `${Requicicao.baseURL}/Produto/ImportarExcel?empresaMatrizId=${selectedMatriz}&atualizarSeExistir=true`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${auth.user?.token}`,
          },
          body: formData,
        }
      );

      if (response.ok) {
        const data = await response.text();
        setProgress(totalRecords); // Garantir que o progresso atinja o total de registros
        setTimeout(() => {
          notify({
            type: "success",
            message: data.message || `Produtos importados com sucesso! Total de registros: ${totalRecords}`,
            life: 5000, // A notificação permanecerá visível por 5 segundos
          });
          setTimeout(() => {
            navigate("/app/produto");
          }, 3000); // Esperar 3 segundos antes de redirecionar
        }, 1000); // Pequeno delay para garantir a exibição do 100% antes de iniciar o processo de notificação e redirecionamento
      } else if (response.status === 400) {
        const errorData = await response.text();
        setValidationError(errorData); // Armazenando o erro de validação no estado
        clearInterval(interval); // Parar o intervalo em caso de erro
        setIsProcessing(false); // Ocultar o loading em caso de erro
        setFile(null); // Resetar o arquivo em caso de erro
      } else {
        notify({
          type: "error",
          message: "Erro desconhecido ao importar produtos.",
        });
        clearInterval(interval); // Parar o intervalo em caso de erro
        setIsProcessing(false); // Ocultar o loading em caso de erro
        setFile(null); // Resetar o arquivo em caso de erro
      }
    } catch (error) {
      console.error("Erro ao importar produtos:", error);
      notify({
        type: "error",
        message: "Erro ao importar produtos.",
      });
      clearInterval(interval); // Parar o intervalo em caso de erro
      setIsProcessing(false); // Ocultar o loading em caso de erro
      setFile(null); // Resetar o arquivo em caso de erro
    }
  };

  const downloadTemplate = () => {
    const worksheet = XLSX.utils.json_to_sheet([
      {
        Sku: "SKU001",
        Codigo: "PROD001",
        Descricao: "Produto Exemplo",
        Grupo: "Grupo Exemplo",
        CodigoGtinEan: "SEM GTIN",
        ValorCusto: "70,59",
        ValorVenda: "100,99",
      },
      {
        Sku: "SKU002",
        Codigo: "PROD002",
        Descricao: "Produto Exemplo 2",
        Grupo: "Outro Grupo Exemplo",
        CodigoGtinEan: "1234567890123",
        ValorCusto: "120",
        ValorVenda: "320",
      },
      {
        Sku: "SKU003",
        Codigo: "PROD003",
        Descricao: "Produto Exemplo 3",
        Grupo: "Grupo Exemplo",
        CodigoGtinEan: "SEM GTIN",
        ValorCusto: "19,99",
        ValorVenda: "320,99",
      },
    ]);

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Produtos");
    XLSX.writeFile(workbook, "Modelo_Importacao_Produto.xlsx");
  };

  return (
    <div className="card p-4">
      <div
        className="alert alert-info"
        style={{ fontSize: "0.8rem", marginBottom: "1rem" }}
      >
        Nota: O tributo associado ao grupo de produtos será propagado
        automaticamente para os produtos durante a importação.
      </div>

      {isProcessing ? (
        <div className="flex justify-content-center align-items-center">
          <ProgressSpinner />
          <div style={{ width: "100%", marginTop: "1rem" }}>
            <ProgressBar value={Math.round((progress / totalRecords) * 100)} />
            <p>
              Importando {Math.min(progress, totalRecords)} de {totalRecords}{" "}
              produtos...
            </p>
          </div>
        </div>
      ) : (
        <>
          <div className="mb-4">
            <label htmlFor="matriz" className="form-label me-2">
              Selecione a Matriz: 
            </label>
            <Dropdown
              id="matriz"
              value={selectedMatriz}
              options={matrizes}
              onChange={(e) => setSelectedMatriz(e.value)}
              placeholder="Selecione a Matriz"
              className="w-full"
              style={{ fontSize: "0.8rem" }}
            />
          </div>

          <div className="mb-4">
            <label htmlFor="file" className="form-label me-2">
              Selecione o Arquivo Excel
            </label>
            <input
              type="file"
              id="file"
              accept=".xlsx"
              onChange={handleFileChange}
              className="form-control"
              style={{ fontSize: "0.8rem" }}
            />
          </div>

          {validationError && (
            <div className="alert alert-danger" style={{ fontSize: "0.8rem" }}>
              <div style={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                  label="Baixar Erros"
                  icon="pi pi-download"
                  className="mt-2"
                  style={{ fontSize: "0.8rem" }}
                  onClick={downloadErrors}
                />
              </div>
              {validationError.split("\n").map((error) => (
                  <p key={error}>{error}</p>
              ))}              
            </div>
          )}

          <div className="d-flex justify-content-between align-items-center">
            <Button
              label="Importar Produtos"
              icon="pi pi-upload"
              style={{ fontSize: "0.8rem" }}
              onClick={handleUpload}
              disabled={isProcessing || !file || !selectedMatriz}
            />
            <Button
              label="Baixar Template"
              icon="pi pi-download"
              style={{ fontSize: "0.8rem" }}
              onClick={downloadTemplate}
            />
          </div>

          <div
            style={{ fontSize: "0.7rem", color: "#888", marginTop: "0.5rem" }}
          >
            * Certifique-se de que os tributos estejam corretos após a
            importação.
          </div>
        </>
      )}

      <Dialog
        visible={showConfirmDialog}
        onHide={() => setShowConfirmDialog(false)}
        header="Confirmação de Importação"
        footer={
          <div>
            <Button
              label="Cancelar"
              icon="pi pi-times"
              onClick={() => setShowConfirmDialog(false)}
              className="p-button-text"
            />
            <Button
              label="Confirmar"
              icon="pi pi-check"
              onClick={confirmUpload}
              autoFocus
            />
          </div>
        }
      >
        <p>
          Ao prosseguir, produtos com o mesmo código para a mesma matriz serão
          atualizados. Deseja continuar?
        </p>
      </Dialog>
    </div>
  );
};

export default ImportarProduto;
