import { useState, useEffect } from "react";

import { Button } from "primereact/button";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";

import { useAuth } from "context/AuthContext";
import { useLoading } from "context/LoadingContext";
import { useNotification } from "context/NotificationContext";

import Api from "utils/Api";

import ImportarEstoque from "pages/listaEstoque/ImportarEstoque";
import DialogTransferirEstoque from "./DialogTransferirEstoque";
import SidebarEditarEstoque from './SidebarEditarEstoque';
import TabelaEstoqueComPaginacao from './TabelaEstoqueComPaginacao';

const ListaEstoque = () => {
  const { setLoading } = useLoading();
  const auth = useAuth();
  const Requicicao = new Api();
  const notify = useNotification();

  const [Lista, setLista] = useState(null);
  const [FilialId, setFilialId] = useState(null); // ID da filial para requisição inicial

  const [ListaEmpresas, setListaEmpresas] = useState([]);
  const [SelectedEmpresa, setSelectedEmpresa] = useState(null);

  const [ShowModal, setShowModal] = useState(false);
  const [EmpresaId, setEmpresaId] = useState(null);

  const [SelectedTipoAcao, setSelectedTipoAcao] = useState(null);
  const [EditData, setEditData] = useState({});
  const [Quantidade, setQuantidade] = useState(null);

  const [ShowModalTransferecia, setShowModalTransferecia] = useState(false);
  const [SelectedDoEstoque, setSelectedDoEstoque] = useState(null);
  const [SelectedParaEstoque, setSelectedParaEstoque] = useState(null);
  const [SelectedProdutoTransferecia, setSelectedProdutoTransferecia] = useState(null);
  const [ListaProdutos, setListaProdutos] = useState([]);

  const [page, setPage] = useState(1); // Página atual
  const [rows, setRows] = useState(100); // Quantidade de linhas por página
  const [totalRecords, setTotalRecords] = useState(0); // Total de registros no backend

  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm, setDebouncedSearchTerm] = useState(searchTerm);

  const [showImportarEstoque, setShowImportarEstoque] = useState(false);

  const [showNegativeStock, setShowNegativeStock] = useState(false); // Filtro de estoque negativo

  const TransfereciaProduto = async (data) => {
    await Requicicao.Patch({
      endpoint: "/EstoquePorEmpresa/Transferir",
      data,
      config: auth.GetHeaders()
    });
  };

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedSearchTerm(searchTerm); // Apenas atualiza após 1 segundo
    }, 1000);
  
    return () => clearTimeout(handler); // Limpa o timeout ao alterar `searchTerm`
  }, [searchTerm]);
  

  const TransfereciaEstoque = async (event) => {
    event.preventDefault();
    try {
      setLoading(true);
      SelectedProdutoTransferecia.map(async (item) => {
        await TransfereciaProduto({
          produtoId: item.code,
          empresaExpedidoraId: SelectedDoEstoque.code,
          empresaDestinoId: SelectedParaEstoque.code,
          quantidade: item.quantidade
        });
      });
      notify({
        type: "success",
        message: "Estoque transferido com sucesso."
      });
      setShowModalTransferecia(false);
    } catch (error) {
      notify({
        type: "error",
        message: "Erro na transferecia de estoque."
      });
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const SelectEditData = (editData) => {
    const { produtoId, quantidade } = editData;
    setEditData({ produtoId, quantidade, empresaId: EmpresaId });
  };

  const GetAllEmpresas = async () => {
    try {
        const resposta = await Requicicao.Get({
            endpoint: "/Empresa/ObterTodasMatrizEFiliaisPorMatrizAutenticada",
            config: auth.GetHeaders()
        });
        // Adicione a opção 'Todos'
        const todasEmpresas = resposta.objetoResposta.map((item) => ({
            code: item.id,
            name: `${item.codigoLojaQueEstaInserida} - ${item.apelidoOuNomeFantasia} ${item.cpfOuCnpj}`
        }));
      
        setListaEmpresas(todasEmpresas);
    } catch (error) {
        notify({
            type: "error",
            message: "Erro ao obter a lista de empresas."
        });
        console.error(error);
    }
};

const GetEstoquePorFilial = async (filialId) => {
  try {
    setLoading(true);
    const data = await Requicicao.Get({
      endpoint: "/EstoquePorEmpresa/ObterPorFilial",
      params: { 
        id: filialId, 
        page, 
        rows,
        searchTerm: debouncedSearchTerm,
        showNegativeStock 
      },
      config: auth.GetHeaders(),
    });
    if (data.objetoResposta) {
      const produtos = data.objetoResposta.produtosQuantidades.map((item) => ({
        produtoCodigo: item.produtoCodigo,
        produtoSku: item.produtoSku,
        produtoCodigoGtinEan: item.produtoCodigoGtinEan,
        produtoId: item.produtoId,
        produtoNome: item.produtoNome,
        quantidade: item.quantidade,
      }));
      setLista(produtos);
      setTotalRecords(data.objetoResposta.totalRecords); // Total de registros retornado pelo backend
    } else {
      notify({
        type: "info",
        message: showNegativeStock 
          ? "Nenhum produto com estoque negativo encontrado." 
          : "Nenhum estoque encontrado para a filial."
      });
      setLista([]);
      setTotalRecords(0);
    }
  } catch (error) {
    notify({ type: "warn", message: "Produto não encontrado." });
    console.error(error);
  } finally {
    setLoading(false);
  }
};
  
const SubmitEditarEstoque = async (event) => {
  event.preventDefault();
  try {
    if (SelectedTipoAcao !== null) {
      let response;
      if (SelectedTipoAcao.code === "reposicao") {
        response = await Requicicao.Patch({
          endpoint: "/EstoquePorEmpresa/Repor",
          data: { ...EditData, quantidade: Quantidade },
          config: auth.GetHeaders()
        });

        setLista((prevLista) =>
          prevLista.map((produto) =>
            produto.produtoId === EditData.produtoId
              ? { ...produto, quantidade: produto.quantidade + Quantidade }
              : produto
          )
        );
      }
      if (SelectedTipoAcao.code === "ajuste") {
        response = await Requicicao.Patch({
          endpoint: "/EstoquePorEmpresa/Ajustar",
          data: { ...EditData, quantidade: Quantidade },
          config: auth.GetHeaders()
        });
        
        setLista((prevLista) =>
          prevLista.map((produto) =>
            produto.produtoId === EditData.produtoId
              ? { ...produto, quantidade: Quantidade }
              : produto
          )
        );
      }

      notify({
        type: "success",
        message: "Estoque atualizado com sucesso."
      });
      setShowModal(false);

      
        
    } else {
      notify({
        type: "aviso",
        message: "SelectedTipoAcao é nulo."
      });
    }
  } catch (error) {
    notify({
      type: "error",
      message: "Erro ao editar o estoque."
    });
    console.error(error);
  }
};
  
  const GetListaProdutos = async (id) => {
    try {
      setLoading(true);
      const data = await Requicicao.Get({
        endpoint: "/EstoquePorEmpresa/ObterPorFilial",
        params: { id },
        config: auth.GetHeaders()
      });
      if (data.objetoResposta !== null) {
        setListaProdutos(
          data.objetoResposta.produtosQuantidades.map((item) => ({
            code: item.produtoId,
            name: item.produtoNome,
            quantidade: item.quantidade
          }))
        );
      } else {
        notify({
          type: "info",
          message: "Não há estoque para esta empresa."
        });
        setListaProdutos([]);
      }
    } catch (error) {
      notify({
        type: "error",
        message: "Erro ao obter o estoque da empresa."
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    GetAllEmpresas();
  }, []);

  useEffect(() => {
    if (FilialId) {
      GetEstoquePorFilial(FilialId);
    }
  }, [FilialId, page, rows, debouncedSearchTerm, showNegativeStock]); // Inclui showNegativeStock como dependência

  useEffect(() => {
    if (SelectedTipoAcao !== null) {
      if (SelectedTipoAcao.code === "reposicao") setQuantidade(0);
      if (SelectedTipoAcao.code === "ajuste")
        setQuantidade(EditData.quantidade);
    }
  }, [SelectedTipoAcao]);

  const handleRefreshGrid = () => {
    GetEstoquePorFilial(FilialId);
  };

  return (
    <div>      
      <DialogTransferirEstoque
        ShowModalTransferecia={ShowModalTransferecia}
        setShowModalTransferecia={setShowModalTransferecia}
        TransfereciaEstoque={TransfereciaEstoque}
        SelectedDoEstoque={SelectedDoEstoque}
        setSelectedDoEstoque={setSelectedDoEstoque}
        SelectedParaEstoque={SelectedParaEstoque}
        setSelectedParaEstoque={setSelectedParaEstoque}
        SelectedProdutoTransferecia={SelectedProdutoTransferecia}
        setSelectedProdutoTransferecia={setSelectedProdutoTransferecia}
        ListaEmpresas={ListaEmpresas}
        ListaProdutos={ListaProdutos}
        GetListaProdutos={GetListaProdutos}
      />
      <SidebarEditarEstoque
        ShowModal={ShowModal}
        setShowModal={setShowModal}
        SubmitEditarEstoque={SubmitEditarEstoque}
        SelectedTipoAcao={SelectedTipoAcao}
        setSelectedTipoAcao={setSelectedTipoAcao}
        Quantidade={Quantidade}
        setQuantidade={setQuantidade}
      />

      <div className="card p-3">
        <div className="d-flex justify-content-between mb-3">
          <div className="d-flex flex-row gap-3">
            <Button
                type="button"
                className="btn btn-primary"
                onClick={() => setShowModalTransferecia(true)}
              >
              Transferir Estoque
            </Button>
            {!showImportarEstoque ? (
              <Button
                onClick={() => setShowImportarEstoque(true)}
                style={{ backgroundColor: "#ff5722", color: "white", borderColor: "#e64a19" }}
              >
                Importar Estoque
              </Button>
            ) : (
              <Button
                onClick={() => {
                  setShowImportarEstoque(false);
                  handleRefreshGrid();
                }}
                style={{ backgroundColor: "#4caf50", color: "white", borderColor: "#388e3c" }}
              >
                Listagem Estoque
              </Button>)}
              {!showImportarEstoque && (
                <Button
                  type="button"
                  className="btn btn-info"
                  onClick={handleRefreshGrid} // Função para recarregar a listagem
                  style={{ backgroundColor: "#2196f3", color: "white", borderColor: "#1976d2" }}
                >
                  Recarregar Estoque
                </Button>
              )}
          </div>
          <div className="d-flex gap-3">
            {!showImportarEstoque && (
              <InputText
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                placeholder="Pesquisar por código ou nome"
                style={{ width: "300px" }}
              />
            )}
            {!showImportarEstoque && (
              <Dropdown
                value={SelectedEmpresa}
                onChange={(e) => {
                  setSelectedEmpresa(e.value);
                  setEmpresaId(e.value.code);
                  setFilialId(e.value.code); // Atualiza o FilialId ao selecionar uma nova empresa
                }}
                options={ListaEmpresas}
                optionLabel="name"
                style={{ width: "300px" }}
                placeholder="Filtro por empresa"
              />)}
              <br/>
          </div>          
        </div>
        <div className="d-flex justify-content-end mb-3">
            {!showImportarEstoque && (<div className="form-check">
                <input
                  type="checkbox"
                  className="form-check-input"
                  id="negativeStockFilter"
                  checked={showNegativeStock}
                  onChange={(e) => setShowNegativeStock(e.target.checked)}
                />
                <label className="form-check-label" htmlFor="negativeStockFilter">
                  Mostrar apenas produtos com estoque negativo
                </label>
              </div>)}
          </div>
        {showImportarEstoque ? (
          <ImportarEstoque
            ListaEmpresas={ListaEmpresas} // Certifique-se de que este array contém os dados corretos
            selectedEmpresa={SelectedEmpresa} // Passe o estado do Dropdown já selecionado
            onSelectEmpresa={(empresa) => { // Atualize a empresa selecionada no pai
              setSelectedEmpresa(empresa);
              setEmpresaId(empresa.code);
              setFilialId(empresa.code);
            }}
            onRefresh={handleRefreshGrid}
            onBack={() => setShowImportarEstoque(false)} // Função para voltar à tabela
          />
        ) : (
          <TabelaEstoqueComPaginacao
            Lista={Lista}
            rows={rows}
            SelectedEmpresa={SelectedEmpresa}
            totalRecords={totalRecords}
            page={page}
            setPage={setPage}
            SelectEditData={SelectEditData}
            setShowModal={setShowModal}
          />
        )}
      </div> 
    </div> 
  );
};

export default ListaEstoque;
