import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { Button } from "primereact/button";
import { Card } from "primereact/card";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";
import { Message } from "primereact/message";
import { Tooltip } from "primereact/tooltip";

import ExportToExcel from "components/ExportToExcel";

import { useAuth } from "context/AuthContext";
import { useLoading } from "context/LoadingContext";
import { useNotification } from "context/NotificationContext";

import Api from "utils/Api";
import MaskUtil from "utils/MaskUtil";




const ListaEmpresas = () => {
  const { setLoading } = useLoading();
  const auth = useAuth();
  const Requicicao = new Api();
  const notify = useNotification();

  const [Lista, setLista] = useState([]);
  const [expandedRows, setExpandedRows] = useState(null);
  const [filteredLista, setFilteredLista] = useState([]);
  const [globalFilterValue, setGlobalFilterValue] = useState("");
  const [showPendingFiliais, setShowPendingFiliais] = useState(false);
  const [showVendedoresModal, setShowVendedoresModal] = useState(false);
  const [selectedVendedores, setSelectedVendedores] = useState([]);
  const [mostrarSenha, setMostrarSenha] = useState(false);
  const [showConfirmDelete, setShowConfirmDelete] = useState(false);
  const [empresaParaExcluir, setEmpresaParaExcluir] = useState(null);
  const [showContasModal, setShowContasModal] = useState(false);
  const [selectedContas, setSelectedContas] = useState([]);
  const [duplicados, setDuplicados] = useState([]);
  const [modalVisible, setModalVisible] = useState(false); // Controle do modal
  const [tipoBusca, setTipoBusca] = useState(""); // "matriz" ou "filial"


  const fetchUltimaVenda = async () => {
    try {
      const resposta = await Requicicao.Get({
        endpoint: "/Venda/ObterDetalhesUltimaVenda",
        config: auth.GetHeaders(),
      });

      if (resposta.codigoEstadoHttp === 200) {
        return resposta.objetoResposta;
      }
      console.error("Erro ao buscar detalhes da última venda:", resposta.mensagemAdicional);
      return [];
    } catch (error) {
      console.error("Erro na API de detalhes da última venda:", error);
      return [];
    }
  };
  
  
  const fetchSeriesConfigurations = async () => {
    try {
      setLoading(true);
      const resposta = await Requicicao.Get({
        endpoint: "/ConfiguracaoSistema/ObterConfiguracoesSeriesNFs",
        config: auth.GetHeaders()
      });
  
      if (resposta.codigoEstadoHttp === 200) {
        const seriesData = resposta.objetoResposta;
        return seriesData;
      }
      console.error("Erro ao buscar configurações de séries:", resposta.mensagemAdicional);
      return [];
      
    } catch (error) {
      console.error("Erro na API de séries:", error);
      return [];
    } finally {
      setLoading(false);
    }
  };
  
  const updateSeriesConfigurations = async () => {
    try {
      const seriesConfigurations = await fetchSeriesConfigurations();
      const ultimaVenda = await fetchUltimaVenda();
      setLista((prevLista) =>
        prevLista.map((empresa) => {
          const matchedConfig = seriesConfigurations.find(
            (config) =>
              config.codigoLoja === empresa.codigoLojaAtual &&
              config.cnpjFilial === empresa.cnpj
          );
  
          const vendaDetalhes = ultimaVenda.find(
            (venda) =>
              venda.codigoLoja === empresa.codigoLojaAtual &&
              venda.cnpjFilial === empresa.cnpj
          );
  
          return {
            ...empresa,
            seriesConfig: matchedConfig
              ? {
                  nfce: matchedConfig.numeroSerieUtilizadoNFCE || null,
                  nfeEntrada: matchedConfig.numeroSerieUtilizadoNFEEntrada || null,
                  nfeSaida: matchedConfig.numeroSerieUtilizadoNFESaida || null,
                }
              : empresa.seriesConfig, // Mantém os valores existentes
            ultimaVenda: vendaDetalhes
              ? {
                  numeroSerieCupom: vendaDetalhes.numeroSerieCupom,
                  numeroVenda: vendaDetalhes.numeroVenda,
                }
              : empresa.ultimaVenda, // Mantém os valores existentes
          };
        })
      );
    } catch (error) {
      console.error("Erro ao atualizar configurações de séries:", error);
    }
  };

  const StateDataPage = async () => {
    try {
      setLoading(true);
      const resposta = await Requicicao.Get({
        endpoint: "/Empresa/ObterTodosVendedor",
        config: auth.GetHeaders()
      });
      if (resposta.codigoEstadoHttp === 200) {
        const lista = resposta.objetoResposta.map((element) => {
          const possuiUsuarioPDV = element.contas.some(c => c.acesso.includes("@pdv"));
          const possuiUsuarioPortal = element.contas.some(c => c.acesso.includes("@portal"));
          const possuiUsuarioCaixa = element.contas.some(c => c.acesso.includes("@caixa"));
          const possuiUsuarioSincronizador = element.contas.some(c => c.acesso.includes("Sincronizador"));
          return {
            id: element.id,
            nome: element.pessoa.nomeCompletoOuRazaoSocial,
            nomeFantasia: element.pessoa.apelidoOuNomeFantasia,
            cnpj: element.pessoa.cpfOuCnpj,
            ehMatriz: element.ehMatriz,
            matrizId: element.ehMatriz ? null : element.matrizId,
            dataCriacao: element.dataCriacao,
            dataAtualizacao: element.dataAtualizacao,
            codigoLojaAtual: element.codigoLojaAtual,
            operador: element.operador,
            telefone: element.pessoa.telefones?.[0]
              ? `${element.pessoa.telefones[0].ddd} ${element.pessoa.telefones[0].numero}`
              : null,
            email: element.pessoa.emails?.[0]?.emailDaPessoa || null,
            endereco: element.pessoa.enderecos?.[0]
              ? `${element.pessoa.enderecos[0].enderecoDaPessoa}, ${element.pessoa.enderecos[0].numero} - ${element.pessoa.enderecos[0].bairro}, ${element.pessoa.enderecos[0].cidade} - ${element.pessoa.enderecos[0].estado}`
              : null,
            vendedores: element.vendedores || [],
            possuiProdutos: element.possuiProdutos || false,
            senhaCertificadoDigital: element.senhaCertificadoDigital || "N/A",
            csc: element.csc || "N/A",
            idcsc: element.idcsc || "N/A",
            anyDesk: element.anyDesk || "N/A",
            inscricaoEstadual: element.pessoa.inscricaoEstadual || "N/A",
            contas: element.contas || [],
            possuiUsuarioPDV,
            possuiUsuarioPortal,
            possuiUsuarioCaixa,
            possuiUsuarioSincronizador
          };
        });
        
        // Identificar códigos duplicados
        const codigoCounts = lista.reduce((acc, empresa) => {
          if (
            empresa.codigoLojaAtual !== "N/A" && // Ignorar "N/A"
            empresa.matrizId && // Verificar se possui matrizId
            lista.find((matriz) => matriz.id === empresa.matrizId) // Verificar se matrizId existe na lista
          ) {
            acc[empresa.codigoLojaAtual] = (acc[empresa.codigoLojaAtual] || 0) + 1;
          }
          return acc;
        }, {});

        // Filtrar duplicados considerando a matrizId associada
        const duplicadosLista = lista.filter(
          (empresa) =>
            empresa.codigoLojaAtual !== "N/A" && // Ignorar "N/A" na filtragem
            empresa.matrizId && // Deve possuir matrizId
            lista.find((matriz) => matriz.id === empresa.matrizId) && // MatrizId deve ser válida
            codigoCounts[empresa.codigoLojaAtual] > 1 // Verificar duplicidade
        );

        // Atualiza os estados
        setLista(lista);
        setDuplicados(duplicadosLista);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };


  const confirmDeleteEmpresa = (empresa) => {
    setEmpresaParaExcluir(empresa);
    setShowConfirmDelete(true);
  };

  const deleteEmpresa = async () => {
    if (!empresaParaExcluir) return;

    try {
      setLoading(true);
      await Requicicao.Delete({
        endpoint: "/Empresa",
        params: { id: empresaParaExcluir.id },
        config: auth.GetHeaders()
      });
      notify({
        type: "success",
        message: "Exclusão realizada com sucesso."
      });
      setShowConfirmDelete(false);
      StateDataPage().then(updateSeriesConfigurations);
    } catch (error) {
      console.error(error);
      notify({
        type: "error",
        message: "Erro ao executar essa ação, entre em contato com o suporte."
      });
    } finally {
      setLoading(false);
    }
  };

  const transformDataForExport = () => 
    Lista.map(matriz => {
      const filiais = Lista.filter(empresa => empresa.matrizId === matriz.id);
      const filialInfo = filiais.map(filial => ({
        CodigoLoja: filial.codigoLojaAtual || 'N/A',
        Matriz: matriz.nome,
        Operador: matriz.operador,
        CNPJMatriz: matriz.cnpj,
        MatrizID: matriz.id,
        Filial: filial.nomeFantasia || 'N/A',
        CNPJ: filial.cnpj ? filial.cnpj.toString() : 'N/A',
        Telefone: filial.telefone ? filial.telefone.toString() : 'N/A',
        Email: filial.email || 'N/A',
        Endereço: filial.endereco || 'N/A',
        "Data de Criação": filial.dataCriacao ? MaskUtil.applyDataAndHoraMask(filial.dataCriacao).toString() : 'N/A',
        "Vendedores Cadastrados": filial.vendedores && filial.vendedores.length > 0 ? 'Sim' : 'Não',
        "Produtos Cadastrados": filial.possuiProdutos ? 'Sim' : 'Não',
        "Possui usuário @pdv": filial.contas && filial.contas.some(conta => conta.acesso && conta.acesso.includes('@pdv')) ? 'Sim' : 'Não',
        "Possui usuário @portal": filial.contas && filial.contas.some(conta => conta.acesso && conta.acesso.includes('@portal')) ? 'Sim' : 'Não',
        "Possui usuário @caixa": filial.contas && filial.contas.some(conta => conta.acesso && conta.acesso.includes('@caixa')) ? 'Sim' : 'Não',
        "Possui usuário Sincronizador": filial.contas && filial.contas.some(conta => conta.acesso && conta.acesso.includes('Sincronizador')) ? 'Sim' : 'Não',
        CSC: filial.csc ? filial.csc.toString() : 'N/A',
        IDCSC: filial.idcsc ? filial.idcsc.toString() : 'N/A',
        "Senha Certificado Digital": filial.senhaCertificadoDigital || "N/A",
        AnyDesk: filial.anyDesk ? filial.anyDesk.toString() : 'N/A',
        "Inscrição Estadual": filial.inscricaoEstadual || "N/A",
      }));
      return {
        Operador: matriz.operador,
        MatrizID: matriz.id,
        CNPJMatriz: matriz.cnpj,
        Matriz: matriz.nome,
        Filiais: filialInfo
      };
    });

  const flattenDataForExport = (data) => 
    data.reduce((acc, curr) => {
        curr.Filiais.forEach(filial => {
            const matrizData = {
                CodigoLoja: filial.CodigoLoja || 'N/A',  // Incluindo o operador da matriz
                Operador: curr.Operador || 'N/A',  // Incluindo o operador da matriz
                NomeMatriz: curr.Matriz || 'N/A',  // Incluindo o nome da matriz
                MatrizID: curr.MatrizID || 'N/A',        // Incluindo o ID da matriz
                CNPJMatriz: curr.CNPJMatriz || 'N/A',   // Incluindo o CNPJ da matriz
                Filial: filial.Filial || 'N/A',
                CNPJ: filial.CNPJ || 'N/A',
                Telefone: filial.Telefone || 'N/A',
                Email: filial.Email || 'N/A',
                Endereço: filial.Endereço || 'N/A',
                "Data de Criação": filial["Data de Criação"] || 'N/A',
                "Vendedores Cadastrados": filial["Vendedores Cadastrados"] || 'Não',
                "Produtos Cadastrados": filial["Produtos Cadastrados"] || 'Não',
                "Possui usuário @pdv": filial["Possui usuário @pdv"] || 'Não',
                "Possui usuário @portal": filial["Possui usuário @portal"] || 'Não',
                "Possui usuário @caixa": filial["Possui usuário @caixa"] || 'Não',
                "Possui usuário Sincronizador": filial["Possui usuário Sincronizador"] || 'Não',
                CSC: filial.CSC || 'N/A',
                IDCSC: filial.IDCSC || 'N/A',
                "Senha Certificado Digital": filial["Senha Certificado Digital"] || 'N/A',
                AnyDesk: filial.AnyDesk || 'N/A',
                "Inscrição Estadual": filial["Inscrição Estadual"] || 'N/A',
            };
            acc.push(matrizData);
        });
        return acc;
    }, []);

    const OnGlobalFilterChange = (e) => {
      const { value } = e.target; // Valor do input
      const trimmedValue = value.trim().toLowerCase(); // Remove espaços ao redor e converte para minúsculas
      setGlobalFilterValue(value);
    
      // Função auxiliar para aplicar trim em campos
      const ltrim = (str) => str?.replace(/^\s+/, ""); // Remove espaços à esquerda
      const rtrim = (str) => str?.replace(/\s+$/, ""); // Remove espaços à direita
    
      // Filtrar as empresas pelo critério de busca
      const filteredData = Lista.filter((empresa) =>
        (ltrim(rtrim(empresa.nome?.toLowerCase())) || "").includes(trimmedValue) || // Busca no nome
        (ltrim(rtrim(empresa.id?.toLowerCase())) || "").includes(trimmedValue) || // Busca no nome
        (ltrim(rtrim(empresa.nomeFantasia?.toLowerCase())) || "").includes(trimmedValue) || // Busca no nome fantasia
        (ltrim(rtrim(empresa.operador?.toLowerCase())) || "").includes(trimmedValue) || // Busca no operador
        (empresa.cnpj?.trim() || "").includes(trimmedValue) // Busca no CNPJ (sem conversão para letras minúsculas, pois é numérico)
      );
    
      // Filtrar as filiais pelo codigoLojaAtual
      const filiais = Lista.filter((empresa) =>
        !empresa.ehMatriz && // Apenas filiais
        (ltrim(rtrim(empresa.codigoLojaAtual?.toLowerCase())) || "").includes(trimmedValue) ||  // Busca no codigoLojaAtual
        (ltrim(rtrim(empresa.id?.toLowerCase())) || "").includes(trimmedValue)   // Busca no GUID
      );
    
      // Adicionar as matrizes correspondentes às filiais encontradas
      filiais.forEach((filial) => {
        const matriz = Lista.find((empresa) => empresa.id === filial.matrizId); // Busca a matriz pela matrizId
        if (matriz && !filteredData.some((empresa) => empresa.id === matriz.id)) {
          // Adiciona a matriz se ainda não estiver na lista filtrada
          filteredData.push(matriz);
        }
      });
    
      // Atualiza a lista filtrada
      setFilteredLista(filteredData);
    };

  useEffect(() => {
    setFilteredLista(Lista);
  }, [Lista]);

  useEffect(() => {
    if (auth.isAuthenticated()) {
      StateDataPage().then(updateSeriesConfigurations);    
    }
  }, [auth]);

  const handleShowVendedores = (vendedores) => {
    setSelectedVendedores(vendedores);
    setShowVendedoresModal(true);
  };

  const handleShowContas = (contas) => {
    setSelectedContas(contas);
    setShowContasModal(true);
  };

  const toggleMostrarSenha = () => {
    setMostrarSenha((prev) => !prev);
  };

  const renderMatrizRow = (rowData) => {
    let filiais = Lista.filter((empresa) => empresa.matrizId === rowData.id);

    if (showPendingFiliais) {
      filiais = filiais.filter(
        (filial) =>
          !filial.telefone ||
          !filial.email ||
          !filial.endereco ||
          filial.vendedores.length === 0 ||
          !filial.possuiProdutos ||
          !filial.contas.some(conta => conta.acesso.includes('Sincronizador')) ||
          !filial.csc || filial.csc === "N/A" ||
          !filial.idcsc || filial.idcsc === "N/A" ||
          !filial.senhaCertificadoDigital || filial.senhaCertificadoDigital === "N/A" ||
          !filial.inscricaoEstadual || filial.inscricaoEstadual === "N/A" ||
          !filial.seriesConfig?.nfce || 
          !filial.seriesConfig?.nfeEntrada || 
          !filial.seriesConfig?.nfeSaida 
      );
    }

    return (
      <div className="row">
        <h6 style={{ fontSize: '0.9rem' }}>Filiais</h6>
        {filiais.length > 0 ? (
          filiais.map((filial) => {
            const { nfce, nfeEntrada, nfeSaida } = filial.seriesConfig || {};
            const hasCompleteInfo = 
            filial.telefone &&
            filial.email &&
            filial.endereco &&
            filial.vendedores.length > 0 &&
            filial.possuiProdutos &&
            filial.contas.some(conta => conta.acesso.includes('Sincronizador')) &&
            filial.csc && filial.csc !== "N/A" &&
            filial.idcsc && filial.idcsc !== "N/A" &&
            filial.senhaCertificadoDigital && filial.senhaCertificadoDigital !== "N/A" &&
            filial.inscricaoEstadual && filial.inscricaoEstadual !== "N/A" &&
            nfce &&
            nfeEntrada &&
            nfeSaida;
            return (
              <div className="col-md-4 mb-3 card-filial" key={filial.id}>
                <Card
                  style={{
                    fontSize: '0.75rem',
                    width: '100%',
                    backgroundColor: hasCompleteInfo ? '#ccffcc' : '#ffcccc',
                  }}
                >
                  <div className="d-flex justify-content-between align-items-center">
                    <div className="d-flex align-items-center">
                      <h6 className="mb-0 me-2">{filial.nomeFantasia}</h6>
                      <div className="d-flex flex-row gap-2">
                        <Link
                          to={`/app/empresa/editar?id=${filial.id}`}
                          className="btn btn-tabela btn-sm"
                        >
                          <i className="bi bi-pencil" />
                        </Link>
                        <Button
                          className="btn btn-tabela btn-sm"
                          type="button"
                          icon="bi bi-trash"
                          onClick={() => confirmDeleteEmpresa(filial)}
                        />
                        <Button
                          className={`btn btn-tabela btn-sm ${!hasCompleteInfo ? 'p-button-danger' : ''}`}
                          type="button"
                          icon="bi bi-people"
                          onClick={() => handleShowVendedores(filial.vendedores)}
                          tooltip="Ver Vendedores"
                          tooltipOptions={{ className: 'custom-tooltip' }}
                        />
                        
                        <Button
                          className="btn btn-tabela btn-sm"
                          type="button"
                          icon="bi bi-person-circle"
                          onClick={() => handleShowContas(filial.contas)}
                          tooltip="Ver Contas"
                          tooltipOptions={{ className: 'custom-tooltip' }}
                        />
                      </div>
                    </div>
                  </div>
                  <small>{filial.nome}</small>
                  <p>
                    <strong>GUID:</strong> <small>{filial.id}</small>
                  </p>
                  <p>
                    <strong>CNPJ:</strong> <small>{filial.cnpj}</small>
                  </p>
                  <p>
                    <strong>Código da Loja:</strong> <small>{filial.codigoLojaAtual}</small>
                  </p>
                  <p>
                    <strong>Telefone:</strong> <small>{filial.telefone || 'N/A'}</small>
                  </p>
                  <p>
                    <strong>Email:</strong> <small>{filial.email || 'N/A'}</small>
                  </p>
                  <p>
                    <strong>Endereço:</strong> <small>{filial.endereco || 'N/A'}</small>
                  </p>
                  <p>
                    <strong>Data de Criação:</strong>{" "}
                    <small>{MaskUtil.applyDataAndHoraMask(filial.dataCriacao)}</small>
                  </p>
                  <p>
                    <strong>Vendedores cadastrados:</strong>{" "}
                    <small>{filial.vendedores.length > 0 ? 'Sim' : 'Não'}</small>
                  </p>
                  <p>
                    <strong>Produtos cadastrados:</strong>{" "}
                    <small>{filial.possuiProdutos ? 'Sim' : 'Não'}</small>
                  </p>
                  <p>
                    <strong>Possui usuário @pdv:</strong>{" "}
                    <small>{filial.possuiUsuarioPDV ? 'Sim' : 'Não'}</small>
                  </p>
                  <p>
                    <strong>Possui usuário @portal:</strong>{" "}
                    <small>{filial.possuiUsuarioPortal ? 'Sim' : 'Não'}</small>
                  </p>
                  <p>
                    <strong>Possui usuário @caixa:</strong>{" "}
                    <small>{filial.possuiUsuarioCaixa ? 'Sim' : 'Não'}</small>
                  </p>
                  <p>
                    <strong>Possui usuário Sincronizador:</strong>{" "}
                    <small>{filial.possuiUsuarioSincronizador ? 'Sim' : 'Não'}</small>
                  </p>
                  <p>
                    <strong>CSC:</strong> <small>{filial.csc}</small>
                  </p>
                  <p>
                    <strong>IDCSC:</strong> <small>{filial.idcsc}</small>
                  </p>
                  <p>
                    <strong>Senha Certificado Digital:</strong> 
                    <small>{mostrarSenha ? filial.senhaCertificadoDigital : "********"}</small>
                    <Button
                      type="button"
                      icon={mostrarSenha ? "pi pi-eye-slash" : "pi pi-eye"}
                      onClick={toggleMostrarSenha}
                      className="p-none p-button-text"
                    />
                  </p>
                  <p>
                    <strong>AnyDesk:</strong> <small>{filial.anyDesk}</small>  {/* Exibindo AnyDesk */}
                  </p>
                  <p>
                    <strong>IE:</strong> <small>{filial.inscricaoEstadual}</small>  {/* Exibindo IE */}
                  </p>
                  <hr/>
                  <p><strong>Séries Configuradas:</strong></p>
                  <ul>
                    <li style={{ color: nfce ? "black" : "red" }}>
                      <strong>NFCe:</strong> {nfce || "Não Configurado"}
                    </li>
                    <li style={{ color: nfeEntrada ? "black" : "red" }}>
                      <strong>NFe Entrada:</strong> {nfeEntrada || "Não Configurado"}
                    </li>
                    <li style={{ color: nfeSaida ? "black" : "red" }}>
                      <strong>NFe Saída:</strong> {nfeSaida || "Não Configurado"}
                    </li>
                  </ul>
                  {filial.ultimaVenda && (
                    <>
                      <hr />
                      <p><strong>Dados da Última Venda:</strong></p>
                      <ul>
                        <li>
                          <strong>Número Série:</strong> {filial.ultimaVenda.numeroSerieCupom || "N/A"}
                        </li>
                        <li>
                          <strong>Número Venda:</strong> {filial.ultimaVenda.numeroVenda || "N/A"}
                        </li>
                      </ul>
                    </>
                  )}
                </Card>
              </div>
            );
          })
        ) : (
          <p>Nenhuma filial encontrada.</p>
        )}
      </div>
    );
  };

  return (
    <div className="card p-3">
      {duplicados.length > 0 && (
        <div className="mb-3">
          <Message
            severity="warn"
            text="Códigos duplicados encontrados. Clique para detalhes."
            style={{ cursor: "pointer" }}
            onClick={() => setModalVisible(true)}
          />
        </div>
      )}
      <Dialog
        visible={modalVisible}
        style={{ width: '50vw' }}
        header="Códigos Duplicados"
        modal
        onHide={() => setModalVisible(false)}
      >
        <ul>
          {duplicados.map((dup) => (
            <li key={dup.id}>
              {dup.codigoLojaAtual} - {dup.nomeFantasia}
            </li>
          ))}
        </ul>
      </Dialog>
      <Tooltip target=".pending-tooltip, .small-tooltip" className="custom-tooltip" />
      <div className="d-flex justify-content-between mb-3">
        <div className="d-flex flex-row gap-3">
          <Link to="/app/empresa/new" className="btn btn-primary">
            Criar
          </Link>
        
          <Button
            type="button"
            label="Filtrar dados faltantes"
            icon="pi pi-exclamation-circle"
            onClick={() => setShowPendingFiliais(!showPendingFiliais)}
            className={`pending-tooltip ${showPendingFiliais ? 'p-button-danger' : 'p-button-secondary'}`}
            data-pr-tooltip="Exibe apenas filiais com telefone, email, endereço, vendedor, produto, CSC, IDCSC ou Senha Certificado Digital faltando"
          />
          <ExportToExcel data={flattenDataForExport(transformDataForExport())} fileName="EmpresasMatrizFilial" />
        </div>
        
        <span className="p-input-icon-left">
          <i className="pi pi-search" />
          <InputText
            value={globalFilterValue}
            onChange={OnGlobalFilterChange}
            placeholder="Pesquisa Global"
          />
        </span>
      </div>
      <DataTable
        value={filteredLista.filter((empresa) => empresa.ehMatriz)}
        expandedRows={expandedRows}
        onRowToggle={(e) => setExpandedRows(e.data)}
        rowExpansionTemplate={renderMatrizRow}
        dataKey="id"
        stripedRows
        paginator
        rows={200}
        tableStyle={{ minWidth: "50rem" }}
        emptyMessage="Nenhuma empresa encontrada."
      >
        <Column expander style={{ width: '3em' }} />
        <Column field="id" header="Id" sortable />
        <Column field="operador" header="Operador" sortable />
        <Column field="nome" header="Nome" sortable />
        <Column field="cnpj" header="CNPJ" sortable />
        <Column
          field="dataCriacao"
          header="Data Criação"
          sortable
          body={(rowData) => MaskUtil.applyDataAndHoraMask(rowData.dataCriacao)}
        />
        <Column
          header="Ações"
          body={(rowData) => (
            <div className="d-flex flex-row gap-3">
              <Link
                to={`/app/empresa/editar?id=${rowData.id}`}
                className="btn btn-tabela"
              >
                <i className="bi bi-pencil" />
              </Link>
              <Button
                className="btn btn-tabela"
                type="button"
                icon="bi bi-trash"
                onClick={() => confirmDeleteEmpresa(rowData)}
              />
            </div>
          )}
        />
      </DataTable>

      <Dialog
        header="Confirmação de Exclusão"
        visible={showConfirmDelete}
        onHide={() => setShowConfirmDelete(false)}
        footer={
          <div>
            <Button
              label="Não"
              icon="pi pi-times"
              onClick={() => setShowConfirmDelete(false)}
              className="p-button-text"
            />
            <Button
              label="Sim"
              icon="pi pi-check"
              onClick={deleteEmpresa}
              className="p-button-danger"
            />
          </div>
        }
      >
        <p>Tem certeza de que deseja excluir esta empresa?</p>
      </Dialog>

      <Dialog
        header="Vendedores"
        visible={showVendedoresModal}
        onHide={() => setShowVendedoresModal(false)}
        className="modal-padrao"
      >
        <ul>
          {selectedVendedores.length > 0 ? (
            selectedVendedores.map((vendedor) => (
              <li key={vendedor.id}>
                <strong>{vendedor.nome}</strong> - {vendedor.apelido}
              </li>
            ))
          ) : (
            <p>Nenhum vendedor encontrado.</p>
          )}
        </ul>
      </Dialog>

      <Dialog
        header="Contas"
        visible={showContasModal}
        onHide={() => setShowContasModal(false)}
        className="modal-padrao"
      >
        <ul>
          {selectedContas.length > 0 ? (
            selectedContas.map((conta) => (
              <li key={`${conta.acesso}-${conta.senha}`}>
                <strong>{conta.acesso}</strong> - {mostrarSenha ? conta.senha : "********"}
                <Button
                  type="button"
                  icon={mostrarSenha ? "pi pi-eye-slash" : "pi pi-eye"}
                  onClick={toggleMostrarSenha}
                  className="p-none p-button-text"
                />
              </li>
            ))
          ) : (
            <p>Nenhuma conta encontrada.</p>
          )}
        </ul>
      </Dialog>
    </div>
  );
};

export default ListaEmpresas;
