import React from "react";
import { Sidebar } from "primereact/sidebar";
import { Dropdown } from "primereact/dropdown";
import { InputNumber } from "primereact/inputnumber";
import { Button } from "primereact/button";

const SidebarEditarEstoque = ({
  ShowModal,
  setShowModal,
  SubmitEditarEstoque,
  SelectedTipoAcao,
  setSelectedTipoAcao,
  Quantidade,
  setQuantidade,
}) => 
  (
    <Sidebar
      visible={ShowModal}
      position="right"
      onHide={() => setShowModal(false)}
    >
      <form onSubmit={SubmitEditarEstoque} className="card-body">
        <div className="row">
          <div className="col-12">
            <h5 className="mb-4">Editar de estoque</h5>
          </div>

          <div className="col-12 mb-3">
            <label htmlFor="acaoEstoque" className="form-label">
              Ação
            </label>
            <Dropdown
              id="acaoEstoque"
              className="w-100"
              value={SelectedTipoAcao}
              onChange={(e) => setSelectedTipoAcao(e.value)}
              options={[
                { code: "reposicao", name: "Reposição de quantidade de estoque" },
                { code: "ajuste", name: "Ajustar a quantidade" },
              ]}
              optionLabel="name"
              placeholder="Ação"
            />
          </div>

          <div className="col-12 mb-3">
            <label htmlFor="quantidade" className="form-label">
              Quantidade
            </label>
            <InputNumber
              id="quantidade"
              name="quantidade"
              className="w-100"
              useGrouping={false}
              maxFractionDigits={0}
              locale="pt-br"
              value={Quantidade}
              onChange={(e) => setQuantidade(e.value)}
            />
          </div>

          <div className="col-md-12">
            <div className="w-100 d-flex flex-row-reverse">
              <Button
                type="submit"
                label="Atualizar"
                className="btn btn-primary"
                disabled={SelectedTipoAcao === null || Quantidade === null}
              />
            </div>
          </div>
        </div>
      </form>
    </Sidebar>
  );

export default SidebarEditarEstoque;
