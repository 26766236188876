import { useState, useEffect } from "react";
import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";

import { useAuth } from "context/AuthContext";
import { useLoading } from "context/LoadingContext";
import { useNotification } from "context/NotificationContext";

import Api from "utils/Api";

const ListaHistoricoEnvioXML = () => {
  const { setLoading } = useLoading();
  const auth = useAuth();
  const api = new Api();
  const notify = useNotification();

  const [lista, setLista] = useState([]);
  const [filters, setFilters] = useState({});

  const InitFilters = () => {
    setFilters({
      global: { value: null, matchMode: "contains" },
      empresaNome: { value: null, matchMode: "contains" },
      empresaCnpj: { value: null, matchMode: "contains" },
      lojaNome: { value: null, matchMode: "contains" },
      codigoLoja: { value: null, matchMode: "contains" },
      dataEnvio: { value: null, matchMode: "contains" },
      mesAnoReferencia: { value: null, matchMode: "contains" },
      emailEnviado: { value: null, matchMode: "equals" },
    });
  };

  const ClearFilter = () => {
    InitFilters();
  };

  const OnFilterChange = (field, value) => {
    const updatedFilters = { ...filters };
    updatedFilters[field].value = value;
    setFilters(updatedFilters);
  };

  const CarregarHistorico = async () => {
    try {
      setLoading(true);
      const response = await api.Get({
        endpoint: "/HistoricoEnvioXML/ObterTodos",
        config: auth.GetHeaders(),
      });

      if (response?.codigoEstadoHttp === 200) {
        setLista(response.objetoResposta || []);
      }
    } catch (error) {
      notify({
        type: "error",
        message: "Erro ao carregar histórico de envios",
      });
    } finally {
      setLoading(false);
    }
  };

  const formatarData = (data) => {
    if (!data) return "";
    const date = new Date(data);
    return date.toLocaleDateString("pt-BR", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
  };

  useEffect(() => {
    CarregarHistorico();
    InitFilters();
  }, []);

  return (
    <div>
      <div className="card p-3">
        <div className="d-flex justify-content-between mb-3">
          <Button
            type="button"
            icon="pi pi-filter-slash"
            label="Limpar Filtros"
            onClick={ClearFilter}
          />
        </div>
        <div className="mb-3">
          <div className="d-flex flex-wrap gap-3">
            <InputText
              placeholder="Buscar por Empresa"
              value={filters.empresaNome?.value || ""}
              onChange={(e) => OnFilterChange("empresaNome", e.target.value)}
            />
            <InputText
              placeholder="Buscar por CNPJ"
              value={filters.empresaCnpj?.value || ""}
              onChange={(e) => OnFilterChange("empresaCnpj", e.target.value)}
            />
            <InputText
              placeholder="Buscar por Loja"
              value={filters.lojaNome?.value || ""}
              onChange={(e) => OnFilterChange("lojaNome", e.target.value)}
            />
            <InputText
              placeholder="Buscar por Código Loja"
              value={filters.codigoLoja?.value || ""}
              onChange={(e) => OnFilterChange("codigoLoja", e.target.value)}
            />
            <InputText
              placeholder="Buscar por Data de Envio"
              value={filters.dataEnvio?.value || ""}
              onChange={(e) => OnFilterChange("dataEnvio", e.target.value)}
            />
            <InputText
              placeholder="Buscar por Mês/Ano Referência"
              value={filters.mesAnoReferencia?.value || ""}
              onChange={(e) =>
                OnFilterChange("mesAnoReferencia", e.target.value)
              }
            />
            <InputText
              placeholder="Buscar por Email Enviado"
              value={filters.emailEnviado?.value || ""}
              onChange={(e) =>
                OnFilterChange("emailEnviado", e.target.value)
              }
            />
          </div>
        </div>
        <DataTable
          value={lista}
          paginator
          rows={10}
          globalFilterFields={[
            "empresaNome",
            "empresaCnpj",
            "lojaNome",
            "codigoLoja",
            "dataEnvio",
            "mesAnoReferencia",
            "emailEnviado",
          ]}
          filters={filters}
          emptyMessage="Nenhum histórico encontrado."
        >
          <Column field="empresaNome" header="Empresa" />
          <Column field="empresaCnpj" header="CNPJ" />
          <Column field="lojaNome" header="Loja" />
          <Column field="codigoLoja" header="Código Loja" />
          <Column
            field="dataEnvio"
            header="Data de Envio"
            body={(rowData) => formatarData(rowData.dataEnvio)}
          />
          <Column field="mesAnoReferencia" header="Mês/Ano Referência" />
          <Column
            field="emailEnviado"
            header="Email Enviado"
            body={(rowData) => (rowData.emailEnviado ? "Sim" : "Não")}
          />
        </DataTable>
      </div>
    </div>
  );
};

export default ListaHistoricoEnvioXML;
