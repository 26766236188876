import { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import { useAuth } from "context/AuthContext";

const Home = () => {
  const auth = useAuth();

  // Permissões:
  // 10 para Gestão Eco e 20 para Gestão Cliente

  const itens = [
    {
      label: "Lojas",
      icon: "pi pi-shopping-cart",
      to: "/app/pontoVenda",
      Nivel: [10]
    },
    {
      label: "Balcões",
      icon: "pi pi-building",
      to: "/app/empresa",
      Nivel: [10]
    },
    {
      label: "Contas de Acesso",
      icon: "pi pi-user",
      to: "/app/conta",
      Nivel: [10, 20]
    },
    {
      label: "Grupos de Produtos",
      icon: "pi pi-tags",
      to: "/app/grupo",
      Nivel: [20]
    },
    {
      label: "Produtos",
      icon: "pi pi-mobile",
      to: "/app/produto",
      Nivel: [20]
    },
    { label: "Estoque", icon: "pi pi-box", to: "/app/estoque", Nivel: [20] },
    {
      label: "Inventário",
      icon: "pi pi-check-square",
      to: "/app/inventario",
      Nivel: [20]
    },
    {
      label: "Vendedores",
      icon: "pi pi-briefcase",
      to: "/app/vendedor",
      Nivel: [20]
    },
    {
      label: "Implantações",
      icon: "pi pi-cloud-upload",
      to: "/app/implantacao",
      Nivel: [10]
    },
    {
      label: "Relatório Implantações",
      icon: "pi pi-chart-bar",
      to: "/app/relatorioImplantacaoVendas",
      Nivel: [10]
    },
    {
      label: "Gestão XML",
      icon: "pi pi-folder",
      to: "/app/configuracaoXML",
      Nivel: [10]
    },
    {
      label: "Health Check",
      icon: "pi pi-heart",
      to: "/app/healthCheck",
      Nivel: [10]
    },
    {
      label: "Operadores",
      icon: "pi pi-user-plus",
      to: "/app/operador",
      Nivel: [10]
    },
    {
      label: "Vendas",
      icon: "pi pi-credit-card",
      to: "/app/vendas",
      Nivel: [10, 20]
    },
    {
      label: "Vendas por Vendedor",
      icon: "pi pi-users",
      to: "/app/vendasVendador",
      Nivel: [10, 20]
    },
    {
      label: "Vendas por Produto",
      icon: "pi pi-box", // Ícone de produtos
      to: "/app/vendasPorProduto",
      Nivel: [10, 20]
    },
    {
      label: "Base de Conhecimento",
      icon: "pi pi-book",
      to: "/app/basesConhecimento",
      Nivel: [10, 20]
    },
    {
      label: "Abertura de Loja",
      icon: "pi pi-check-circle",
      to: "/app/relatorio/statusfiliais",
      Nivel: [10]
    },
    {
      label: "Lojas sem Venda",
      icon: "pi pi-check-circle",
      to: "/app/relatorio/lojasSemVenda",
      Nivel: [10]
    },
    {
      label: "Transição Lojas",
      icon: "pi pi-sync",
      to: "/app/relatorio/lojasTransicao",
      Nivel: [10]
    },
  ];

  const [NivelUser, setNivelUser] = useState([]);

  const ValidacaoAcesso = (acessoNecessario, nivelAcesso) =>
    acessoNecessario.some((item) => nivelAcesso.includes(item));

  useEffect(() => {
    if (auth.isAuthenticated()) {
      setNivelUser(auth.GetAccessLevel());
    }
  }, [auth]);

  return (
    <div className="d-flex flex-wrap gap-3">
      {itens.map(
        (item) =>
          ValidacaoAcesso(item.Nivel, NivelUser) && (
            <div key={item.to} className="card-home">
              <Link to={item.to}>
                <div
                  style={{
                    width: "250px",
                    height: "100px",
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "column",
                    justifyContent: "center",
                    textAlign: "center"
                  }}
                >
                  <i className={item.icon} style={{ fontSize: "24px" }} />
                  <span style={{ paddingTop: "10px" }}>{item.label}</span>
                </div>
              </Link>
            </div>
          )
      )}
    </div>
  );
};

export default Home;
