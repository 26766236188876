import { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Button } from "primereact/button";
import { Chips } from "primereact/chips";
import { InputText } from "primereact/inputtext";

import { useAuth } from "context/AuthContext";
import { useLoading } from "context/LoadingContext";
import { useNotification } from "context/NotificationContext";

import Api from "utils/Api";

const FormConfiguracaoEnvioXML = () => {
  const { setLoading } = useLoading();
  const auth = useAuth();
  const api = new Api();
  const notify = useNotification();
  const navigate = useNavigate();
  const { id } = useParams();

  const [data, setData] = useState({
    id: "",
    empresaId: null,
    emailContabilidade: "",
    outrosEmails: null,
    pontoVendaAnfitriaoId: null,
  });

  const [todasEmpresas, setTodasEmpresas] = useState([]);
  const [listaEmpresas, setListaEmpresas] = useState([]);
  const [listaLojas, setListaLojas] = useState([]);
  const [empresaSelecionada, setEmpresaSelecionada] = useState("");
  const [lojaSelecionada, setLojaSelecionada] = useState("");

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const carregarEmpresas = async () => {
    try {
      setLoading(true);
      const response = await api.Get({
        endpoint: "/Empresa",
        config: auth.GetHeaders(),
      });

      if (response?.codigoEstadoHttp === 200) {
        const empresasComOperador = response.objetoResposta.map((empresa) => {
          const matriz = empresa.matrizId
            ? response.objetoResposta.find((e) => e.id === empresa.matrizId)
            : empresa;
          return {
            ...empresa,
            operador: matriz?.operador || "Operador não encontrado",
          };
        });

        const empresas = empresasComOperador.filter(
          (e) => e.codigoLojaAtual !== "N/A"
        );

        setTodasEmpresas(empresas);

        const empresasUnicas = Array.from(
          new Map(
            empresasComOperador.map((e) => [
              e.pessoa.nomeCompletoOuRazaoSocial,
              {
                name: `${e.operador}: ${e.pessoa.nomeCompletoOuRazaoSocial}`,
                rawName: e.pessoa.nomeCompletoOuRazaoSocial,
              },
            ])
          ).values()
        );

        setListaEmpresas(empresasUnicas);
      }
    } catch (error) {
      notify({
        type: "error",
        message: "Erro ao carregar empresas.",
      });
    } finally {
      setLoading(false);
    }
  };

  const carregarLojas = (nomeEmpresa) => {
    const lojas = todasEmpresas
      .filter((e) => e.pessoa.nomeCompletoOuRazaoSocial === nomeEmpresa)
      .map((e) => ({
        name: e.codigoLojaAtual,
        code: e.pontoVendaAnfitriaoId,
        empresaId: e.id,
      }));

    setListaLojas(lojas);
    setLojaSelecionada("");
    setEmpresaSelecionada(nomeEmpresa);
  };

  const carregarConfiguracao = async () => {
    try {
      setLoading(true);
      if (id) {
        const response = await api.Get({
          endpoint: `/ConfiguracaoEnvioXML/ObterPorId`,
          params: { id },
          config: auth.GetHeaders(),
        });

        if (response?.codigoEstadoHttp === 200) {
          const config = response.objetoResposta;

          setData({
            id: config.id,
            empresaId: config.empresaId,
            emailContabilidade: config.emailContabilidade || "",
            outrosEmails: config.outrosEmails || "",
            pontoVendaAnfitriaoId: config.lojaId,
          });

          const empresa = todasEmpresas.find(
            (e) => e.id === config.empresaId
          );
          if (empresa) {
            setEmpresaSelecionada(
              `${empresa.operador}: ${empresa.pessoa.nomeCompletoOuRazaoSocial}`
            );
            carregarLojas(empresa.pessoa.nomeCompletoOuRazaoSocial);
            setLojaSelecionada(config.lojaId);
          }
        }
      }
    } catch (error) {
      notify({
        type: "error",
        message: "Erro ao carregar configuração.",
      });
    } finally {
      setLoading(false);
    }
  };

  const salvarConfiguracao = async (event) => {
    event.preventDefault();

    try {
      setLoading(true);

      if (!data.empresaId || !data.pontoVendaAnfitriaoId) {
        notify({
          type: "warn",
          message: "Por favor, selecione uma empresa e uma loja válida!",
        });
        return;
      }

      const payload = {
        EmpresaId: data.empresaId,
        LojaId: data.pontoVendaAnfitriaoId,
        EmailContabilidade: data.emailContabilidade,
        OutrosEmails: data.outrosEmails || null,
      };

      const response = await api.Post({
        endpoint: "/ConfiguracaoEnvioXML/CriarOuAtualizar",
        data: payload,
        config: auth.GetHeaders(),
      });

      if (response?.codigoEstadoHttp === 200) {
        notify({
          type: "success",
          message: id
            ? "Configuração atualizada com sucesso!"
            : "Configuração criada com sucesso!",
        });
        navigate("/app/configuracaoXML");
      } else {
        notify({
          type: "error",
          message: response?.mensagemAdicional || "Erro ao salvar configuração.",
        });
      }
    } catch (error) {
      notify({
        type: "error",
        message: "Erro ao salvar configuração.",
      });
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (todasEmpresas.length === 0) {
      carregarEmpresas();
    }
    if (id && todasEmpresas.length > 0) {
      carregarConfiguracao();
    }
  }, [id, todasEmpresas]);

  return (
    <div className="card">
      <form onSubmit={salvarConfiguracao} className="card-body">
        <div className="row">
          <div className="col-md-6 mb-3">
            <label className="form-label" htmlFor="empresaId">
              Empresa
            </label>
            <select
              id="empresaId"
              value={empresaSelecionada}
              onChange={(e) => {
                const selectedEmpresa = listaEmpresas.find(
                  (emp) => emp.name === e.target.value
                );
                if (selectedEmpresa) {
                  carregarLojas(selectedEmpresa.rawName);
                  setEmpresaSelecionada(selectedEmpresa.name);
                  setData((prevState) => ({ ...prevState, empresaId: null }));
                }
              }}
              className="form-select"
            >
              <option value="" disabled>
                Selecione uma empresa
              </option>
              {listaEmpresas.map((empresa) => (
                <option key={empresa.name} value={empresa.name}>
                  {empresa.name}
                </option>
              ))}
            </select>
          </div>

          <div className="col-md-6 mb-3">
            <label className="form-label" htmlFor="pontoVendaAnfitriaoId">
              Loja
            </label>
            <select
              id="pontoVendaAnfitriaoId"
              value={lojaSelecionada}
              onChange={(e) => {
                const loja = listaLojas.find(
                  (l) => l.code === e.target.value
                );
                if (loja) {
                  setData({
                    ...data,
                    empresaId: loja.empresaId,
                    pontoVendaAnfitriaoId: loja.code,
                  });
                  setLojaSelecionada(loja.code);
                }
              }}
              className="form-select"
              disabled={listaLojas.length === 0}
            >
              <option value="" disabled>
                Selecione uma loja
              </option>
              {listaLojas.map((loja) => (
                <option key={loja.code} value={loja.code}>
                  {loja.name}
                </option>
              ))}
            </select>
          </div>

          <div className="col-md-6 mb-3">
            <label className="form-label" htmlFor="emailContabilidade">
              Email Contabilidade
            </label>
            <InputText
              id="emailContabilidade"
              name="emailContabilidade"
              value={data.emailContabilidade}
              onChange={handleInputChange}
              className="form-control"
            />
          </div>

          <div className="col-md-6 mb-3">
            <label className="form-label" htmlFor="outrosEmails">
              Outros Emails
            </label>
            <Chips
              id="outrosEmails"
              value={data.outrosEmails ? data.outrosEmails.split(";") : []}
              onChange={(e) =>
                setData({
                  ...data,
                  outrosEmails: e.value.join(";"),
                })
              }
              placeholder="Adicione e-mails e pressione Enter"
              separator=";"
              className="w-100"
            />
          </div>

          <div className="col-md-12 mt-4 d-flex justify-content-end">
            <Button label="Salvar" icon="pi pi-save" type="submit" />
          </div>
        </div>
      </form>
    </div>
  );
};

export default FormConfiguracaoEnvioXML;
