import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { Button } from "primereact/button";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { Dialog } from "primereact/dialog";
import { InputText } from "primereact/inputtext";

import { useAuth } from "context/AuthContext";
import { useLoading } from "context/LoadingContext";
import { useNotification } from "context/NotificationContext";

import Api from "utils/Api";

const ListaConfiguracoesEnvioXML = () => {
  const { setLoading } = useLoading();
  const auth = useAuth();
  const api = new Api();
  const notify = useNotification();
  const navigate = useNavigate();

  const [lista, setLista] = useState([]);
  const [filters, setFilters] = useState({});
  const [exibirConfirmacao, setExibirConfirmacao] = useState(false);
  const [idParaExcluir, setIdParaExcluir] = useState(null); // Armazena o ID da configuração a ser excluída

  const InitFilters = () => {
    setFilters({
      global: { value: null, matchMode: "contains" },
      nomeOperador: { value: null, matchMode: "contains" },
      codigoLoja: { value: null, matchMode: "contains" },
      loja: { value: null, matchMode: "contains" },
      empresaNome: { value: null, matchMode: "contains" },
      empresaCnpj: { value: null, matchMode: "contains" },
      emailContabilidade: { value: null, matchMode: "contains" },
      outrosEmails: { value: null, matchMode: "contains" },
    });
  };

  const ClearFilter = () => {
    InitFilters();
  };

  const OnFilterChange = (field, value) => {
    const updatedFilters = { ...filters };
    updatedFilters[field].value = value;
    setFilters(updatedFilters);
  };

  const CarregarConfiguracoes = async () => {
    try {
      setLoading(true);
      const response = await api.Get({
        endpoint: "/ConfiguracaoEnvioXML/ObterTodas",
        config: auth.GetHeaders(),
      });

      if (response?.codigoEstadoHttp === 200) {
        setLista(response.objetoResposta || []);
      }
    } catch (error) {
      notify({
        type: "error",
        message: "Erro ao carregar configurações",
      });
    } finally {
      setLoading(false);
    }
  };

  const confirmarExclusao = (id) => {
    setIdParaExcluir(id);
    setExibirConfirmacao(true);
  };

  const removerConfiguracao = async () => {
    try {
      setLoading(true);
      await api.Delete({
        endpoint: `/ConfiguracaoEnvioXML/Excluir`,
        params: { id: idParaExcluir },
        config: auth.GetHeaders(),
      });

      notify({
        type: "success",
        message: "Configuração removida com sucesso",
      });
      CarregarConfiguracoes();
    } catch (error) {
      notify({
        type: "error",
        message: "Erro ao remover configuração",
      });
    } finally {
      setLoading(false);
      setExibirConfirmacao(false);
      setIdParaExcluir(null);
    }
  };

  useEffect(() => {
    CarregarConfiguracoes();
    InitFilters();
  }, []);

  return (
    <div>
      <div className="card p-3">
        <div className="d-flex justify-content-between mb-3">
          <Button
            type="button"
            icon="pi pi-filter-slash"
            label="Limpar Filtros"
            onClick={ClearFilter}
          />
          <div className="d-flex gap-2">
            <Button
              label="Nova Configuração"
              icon="pi pi-plus"
              className="p-button-primary"
              onClick={() => navigate("/app/configuracaoXML/new")}
            />
            <Button
              label="Histórico de Envios"
              icon="pi pi-clock"
              className="p-button-secondary"
              onClick={() => navigate("/app/configuracaoXML/historico")}
            />
          </div>
        </div>
        <div className="mb-3">
          <div className="d-flex flex-wrap gap-3">
            <InputText
              placeholder="Buscar por Operador"
              value={filters.nomeOperador?.value || ""}
              onChange={(e) => OnFilterChange("nomeOperador", e.target.value)}
            />
            <InputText
              placeholder="Buscar por Código Loja"
              value={filters.codigoLoja?.value || ""}
              onChange={(e) => OnFilterChange("codigoLoja", e.target.value)}
            />
            <InputText
              placeholder="Buscar por Loja"
              value={filters.loja?.value || ""}
              onChange={(e) => OnFilterChange("loja", e.target.value)}
            />
            <InputText
              placeholder="Buscar por Empresa"
              value={filters.empresaNome?.value || ""}
              onChange={(e) => OnFilterChange("empresaNome", e.target.value)}
            />
            <InputText
              placeholder="Buscar por CNPJ"
              value={filters.empresaCnpj?.value || ""}
              onChange={(e) => OnFilterChange("empresaCnpj", e.target.value)}
            />
            <InputText
              placeholder="Buscar por Email Contabilidade"
              value={filters.emailContabilidade?.value || ""}
              onChange={(e) =>
                OnFilterChange("emailContabilidade", e.target.value)
              }
            />
            <InputText
              placeholder="Buscar por Outros Emails"
              value={filters.outrosEmails?.value || ""}
              onChange={(e) =>
                OnFilterChange("outrosEmails", e.target.value)
              }
            />
          </div>
        </div>
        <DataTable
          value={lista}
          paginator
          rows={10}
          globalFilterFields={[
            "nomeOperador",
            "codigoLoja",
            "loja",
            "empresaNome",
            "empresaCnpj",
            "emailContabilidade",
            "outrosEmails",
          ]}
          filters={filters}
          emptyMessage="Nenhuma configuração encontrada."
        >
          <Column field="nomeOperador" header="Operador" />
          <Column field="codigoLoja" header="Código Loja" />
          <Column field="loja" header="Loja" />
          <Column field="empresaNome" header="Empresa" />
          <Column field="empresaCnpj" header="CNPJ" />
          <Column field="emailContabilidade" header="Email Contabilidade" />
          <Column field="outrosEmails" header="Outros Emails" />
          <Column
            body={(rowData) => (
              <div className="d-flex">
                <Button
                  label="Editar"
                  icon="pi pi-pencil"
                  className="p-button-primary me-4"
                  onClick={() =>
                    navigate(`/app/configuracaoXML/edit/${rowData.id}`)
                  }
                />
                <Button
                  label="Excluir"
                  icon="pi pi-trash"
                  className="p-button-danger"
                  onClick={() => confirmarExclusao(rowData.id)}
                />
              </div>
            )}
            header="Ações"
          />
        </DataTable>
        <Dialog
          header="Confirmação"
          visible={exibirConfirmacao}
          modal
          style={{ width: "350px" }}
          footer={
            <div>
              <Button
                label="Não"
                icon="pi pi-times"
                onClick={() => setExibirConfirmacao(false)}
                className="p-button-text"
              />
              <Button
                label="Sim"
                icon="pi pi-check"
                onClick={removerConfiguracao}
                autoFocus
              />
            </div>
          }
          onHide={() => setExibirConfirmacao(false)}
        >
          <div className="confirmation-content">
            <span>Você tem certeza que deseja excluir esta configuração?</span>
          </div>
        </Dialog>
      </div>
    </div>
  );
};

export default ListaConfiguracoesEnvioXML;
