import React from 'react';
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import CustomPagination from "./CustomPagination";

const TabelaEstoqueComPaginacao = ({
  Lista,
  rows,
  SelectedEmpresa,
  totalRecords,
  page,
  setPage,
  SelectEditData,
  setShowModal
}) => {
  if (!Lista || Lista.length === 0) {
    return <h5 className="text-center">Selecione uma empresa para visualizar o estoque!</h5>;
  }

  return (
    <>
      <DataTable
        value={Lista}
        stripedRows
        rows={rows}
        tableStyle={{ minWidth: "50rem" }}
        emptyMessage="Nenhum produto encontrado."
        className="small-table"
        sortField={SelectedEmpresa ? SelectedEmpresa.name.split(" - ")[0].toLowerCase() : "quantidadeTotal"}
        sortOrder={-1}
      >
        <Column field="produtoCodigo" header="Código" sortable />
        <Column field="produtoSku" header="SKU" sortable />
        <Column field="produtoCodigoGtinEan" header="GTIN/EAN" sortable />
        <Column field="produtoNome" header="Nome" sortable />
        <Column field="quantidade" header="Quantidade" sortable />

        {SelectedEmpresa && (
          <Column
            header="Ações"
            body={(rowData) => (
              <div className="d-flex flex-row gap-3">
                <button
                  type="button"
                  style={{ background: "none", border: "none", padding: 0, color: "black", cursor: "pointer" }}
                  aria-label="Editar"
                  onClick={() => {
                    SelectEditData(rowData);
                    setShowModal(true);
                  }}
                >
                  <i className="pi pi-pencil" style={{ fontSize: "0.8rem" }} />
                </button>
              </div>
            )}
          />
        )}
      </DataTable>

      <div style={{ textAlign: "center", marginTop: "1rem" }}>
        <p>Total de registros: <strong>{totalRecords}</strong></p>
      </div>

      <CustomPagination
        totalRecords={totalRecords}
        rows={rows}
        page={page}
        setPage={setPage}
      />
    </>
  );
};

export default TabelaEstoqueComPaginacao;
