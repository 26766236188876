import './CustomPagination.css';

const CustomPagination = ({ totalRecords, rows, page, setPage }) => {
    const totalPages = Math.ceil(totalRecords / rows);
  
    const getPages = () => {
      const pages = [];
      const startPage = Math.max(1, page - 1);
      const endPage = Math.min(totalPages, page + 1);
  
      for (let i = startPage; i <= endPage; i += 1) {
        pages.push(i);
      }
      return pages;
    };
  
    return (
      <div className="pagination-container" style={{ marginTop: '1rem', textAlign: 'center' }}>
        <button
          type="button"
          className="btn btn-secondary"
          disabled={page === 1}
          onClick={() => setPage(page - 1)}
        >
          Anterior
        </button>
        {getPages().map((p) => (
          <button
            key={p}
            type="button"
            className={`btn ${p === page ? "btn-primary" : "btn-light"}`}
            onClick={() => setPage(p)}
          >
            {p}
          </button>
        ))}
        <button
          type="button"
          className="btn btn-secondary"
          disabled={page === totalPages}
          onClick={() => setPage(page + 1)}
        >
          Próximo
        </button>
      </div>
    );
  };
  
  // Alteração para exportação padrão
  export default CustomPagination;
  